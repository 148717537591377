import React from 'react';
import { navigate } from 'gatsby';
import IncomePage from 'src/px/IncomePage';
import { Page } from '../../components';

const SlasherIncomeView = ({}) => {
  return (
    <Page
      context="Slasher"
      progress={0.45}
      metadata={{
        title: 'Slash your premiums',
        description: 'See how much you could save on your current coverage',
        image: '/img/og/slasher.png',
      }}
    >
      <IncomePage flow="Slasher" onNext={() => navigate('/slasher/savings')} />
    </Page>
  );
};

export default SlasherIncomeView;
